import { Typography } from '@material-ui/core';
import React from 'react';
import { Button, UnstyledLink } from '@queensland-running/qr-components';
import styled from 'styled-components/macro';
import moment from 'moment';

type CTA = {
  url: string;
  text: string;
};
type PostType = {
  title: string;
  author: string;
  id: string;
  date: Date;
  content: string[];
  tags: string[];
  cta?: CTA[];
};

type NewsProps = {
  news: PostType[];
};

type NewsViewProps = NewsProps & {};

const ButtonLayout = styled('div')`
  margin-top: 0.5rem;

  > :not(:first-child) {
      // @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-left: 1rem;
    // }
  }
`;

const Post = ({ title, content, cta, date }: PostType) => (
  <>
    <Typography variant="h5">{title}</Typography>
    <Typography variant="caption" component="p" gutterBottom>
      {moment(date).format('dddd, D MMMM YYYY')}
    </Typography>
    {content.map((text: string) => (
      <Typography key={text.substr(0, 10)} variant="body1" component="p" gutterBottom>
        {text}
      </Typography>
    ))}
    {cta && (
      <ButtonLayout>
        {cta.map(({ url, text }: CTA) => (
          <UnstyledLink key={url} to={url}>
            <Button variant="outlined" color="secondary" title={text} />
          </UnstyledLink>
        ))}
      </ButtonLayout>
    )}
    <br />
  </>
);

const LatestNewsView = ({ news }: NewsViewProps) => (
  <>
    <Typography variant="h3" color="primary" gutterBottom>
      Latest News
    </Typography>
    {news.slice(0, 3).map((post: PostType) => (
      <Post key={post.id} {...post} />
    ))}
  </>
);

export const LatestNews = React.memo(LatestNewsView);
