/* eslint-disable */
import * as Types from '../../../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RegisterRunnersForWeeklyCompetitionMutationVariables = Types.Exact<{
  input: Types.RegisterRunnersForWeeklyCompetitionInputPartial;
}>;

export type RegisterRunnersForWeeklyCompetitionMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerRunnersForWeeklyCompetition: { readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<
        Types.WeeklyCompetitionRegistration,
        'id' | 'orderId' | 'competitionId'
      > & {
          readonly runnerDetails?: Types.Maybe<
            ReadonlyArray<
              { readonly __typename?: 'WeeklyCompetitionRunnerDetails' } & Pick<
                Types.WeeklyCompetitionRunnerDetails,
                'id' | 'firstName' | 'lastName' | 'bibNumber' | 'club' | 'gender' | 'dateOfBirth'
              >
            >
          >;
        };
    }
  >;
};

export type RegisterTeamForRockettRelaysMutationVariables = Types.Exact<{
  input: Types.RegisterTeamForRockettRelaysInputPartial;
}>;

export type RegisterTeamForRockettRelaysMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerTeamForRockettRelays: { readonly __typename?: 'RockettRelaysRegistration' } & Pick<
        Types.RockettRelaysRegistration,
        'id' | 'orderId' | 'competitionId'
      > & {
          readonly teamDetails?: Types.Maybe<
            { readonly __typename?: 'TeamDetails' } & Pick<
              Types.TeamDetails,
              'relay' | 'teamName' | 'ageGroup' | 'gender'
            >
          >;
          readonly runnerDetails?: Types.Maybe<
            ReadonlyArray<
              { readonly __typename?: 'RockettRelaysRunnerDetails' } & Pick<
                Types.RockettRelaysRunnerDetails,
                'dateOfBirth' | 'name' | 'gender' | 'position'
              >
            >
          >;
        };
    }
  >;
};

export const RegisterRunnersForWeeklyCompetitionDocument = gql`
  mutation registerRunnersForWeeklyCompetition($input: RegisterRunnersForWeeklyCompetitionInputPartial!) {
    competition {
      registerRunnersForWeeklyCompetition(input: $input) {
        id
        orderId
        competitionId
        runnerDetails {
          id
          firstName
          lastName
          bibNumber
          club
          gender
          dateOfBirth
        }
      }
    }
  }
`;
export type RegisterRunnersForWeeklyCompetitionMutationFn = Apollo.MutationFunction<
  RegisterRunnersForWeeklyCompetitionMutation,
  RegisterRunnersForWeeklyCompetitionMutationVariables
>;
export type RegisterRunnersForWeeklyCompetitionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables
  >,
  'mutation'
>;

export const RegisterRunnersForWeeklyCompetitionComponent = (
  props: RegisterRunnersForWeeklyCompetitionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables
  >
    mutation={RegisterRunnersForWeeklyCompetitionDocument}
    {...props}
  />
);

export type RegisterRunnersForWeeklyCompetitionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables
  >;
} &
  TChildProps;
export function withRegisterRunnersForWeeklyCompetition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables,
    RegisterRunnersForWeeklyCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables,
    RegisterRunnersForWeeklyCompetitionProps<TChildProps, TDataName>
  >(RegisterRunnersForWeeklyCompetitionDocument, {
    alias: 'registerRunnersForWeeklyCompetition',
    ...operationOptions,
  });
}

/**
 * __useRegisterRunnersForWeeklyCompetitionMutation__
 *
 * To run a mutation, you first call `useRegisterRunnersForWeeklyCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterRunnersForWeeklyCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerRunnersForWeeklyCompetitionMutation, { data, loading, error }] = useRegisterRunnersForWeeklyCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterRunnersForWeeklyCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables
  >,
) {
  return Apollo.useMutation<
    RegisterRunnersForWeeklyCompetitionMutation,
    RegisterRunnersForWeeklyCompetitionMutationVariables
  >(RegisterRunnersForWeeklyCompetitionDocument, baseOptions);
}
export type RegisterRunnersForWeeklyCompetitionMutationHookResult = ReturnType<
  typeof useRegisterRunnersForWeeklyCompetitionMutation
>;
export type RegisterRunnersForWeeklyCompetitionMutationResult = Apollo.MutationResult<
  RegisterRunnersForWeeklyCompetitionMutation
>;
export type RegisterRunnersForWeeklyCompetitionMutationOptions = Apollo.BaseMutationOptions<
  RegisterRunnersForWeeklyCompetitionMutation,
  RegisterRunnersForWeeklyCompetitionMutationVariables
>;
export const RegisterTeamForRockettRelaysDocument = gql`
  mutation registerTeamForRockettRelays($input: RegisterTeamForRockettRelaysInputPartial!) {
    competition {
      registerTeamForRockettRelays(input: $input) {
        id
        orderId
        competitionId
        teamDetails {
          relay
          teamName
          ageGroup
          gender
        }
        runnerDetails {
          dateOfBirth
          name
          gender
          position
        }
      }
    }
  }
`;
export type RegisterTeamForRockettRelaysMutationFn = Apollo.MutationFunction<
  RegisterTeamForRockettRelaysMutation,
  RegisterTeamForRockettRelaysMutationVariables
>;
export type RegisterTeamForRockettRelaysComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterTeamForRockettRelaysMutation,
    RegisterTeamForRockettRelaysMutationVariables
  >,
  'mutation'
>;

export const RegisterTeamForRockettRelaysComponent = (props: RegisterTeamForRockettRelaysComponentProps) => (
  <ApolloReactComponents.Mutation<RegisterTeamForRockettRelaysMutation, RegisterTeamForRockettRelaysMutationVariables>
    mutation={RegisterTeamForRockettRelaysDocument}
    {...props}
  />
);

export type RegisterTeamForRockettRelaysProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterTeamForRockettRelaysMutation,
    RegisterTeamForRockettRelaysMutationVariables
  >;
} &
  TChildProps;
export function withRegisterTeamForRockettRelays<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterTeamForRockettRelaysMutation,
    RegisterTeamForRockettRelaysMutationVariables,
    RegisterTeamForRockettRelaysProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterTeamForRockettRelaysMutation,
    RegisterTeamForRockettRelaysMutationVariables,
    RegisterTeamForRockettRelaysProps<TChildProps, TDataName>
  >(RegisterTeamForRockettRelaysDocument, {
    alias: 'registerTeamForRockettRelays',
    ...operationOptions,
  });
}

/**
 * __useRegisterTeamForRockettRelaysMutation__
 *
 * To run a mutation, you first call `useRegisterTeamForRockettRelaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTeamForRockettRelaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTeamForRockettRelaysMutation, { data, loading, error }] = useRegisterTeamForRockettRelaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterTeamForRockettRelaysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterTeamForRockettRelaysMutation,
    RegisterTeamForRockettRelaysMutationVariables
  >,
) {
  return Apollo.useMutation<RegisterTeamForRockettRelaysMutation, RegisterTeamForRockettRelaysMutationVariables>(
    RegisterTeamForRockettRelaysDocument,
    baseOptions,
  );
}
export type RegisterTeamForRockettRelaysMutationHookResult = ReturnType<typeof useRegisterTeamForRockettRelaysMutation>;
export type RegisterTeamForRockettRelaysMutationResult = Apollo.MutationResult<RegisterTeamForRockettRelaysMutation>;
export type RegisterTeamForRockettRelaysMutationOptions = Apollo.BaseMutationOptions<
  RegisterTeamForRockettRelaysMutation,
  RegisterTeamForRockettRelaysMutationVariables
>;
