import React, {useEffect} from 'react';
import {LoadingComponent} from '@queensland-running/qr-components';
import {StepProps} from '../event-registration-flow';
import {RockettRelayRegistration} from './runner-registration/rockett-relay-registration';
import {WeeklyRegistration} from "./runner-registration/weekly-registration";
import {useWeeklyRegistrationContext} from "../../../../contexts";

export const SelectRunnersStep = (props: StepProps) => {
  const { isRunnerDetailsReady } = useWeeklyRegistrationContext()

  useEffect(() => {
    if (isRunnerDetailsReady === false) {
      const timer = setTimeout(() => {
        props.handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [isRunnerDetailsReady, props.handleBack])

  if (isRunnerDetailsReady === null) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming runner details..."/>
      </>
    );
  }

  switch (props.competitionType) {
    case 'WeeklyCompetition':
      return <WeeklyRegistration {...props} />;
    case 'RockettRelays':
      return <RockettRelayRegistration {...props} />;
    default:
      return null;
  }
};
