import React, {FunctionComponent, useState} from 'react';
import {useFormik} from 'formik';
import {boolean, object} from 'yup';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {map} from 'lodash/fp';
import {
  Blockquote,
  FieldValue,
} from '@queensland-running/qr-components';
import {ConditionsOfEntryDialog} from '../../modals/terms-modal';
import {
  AllSchoolsChampionships,
  RockettRelays,
  WeeklyCompetition,
  WinterChampionships
} from "@generated/schema";
import {useWeeklyRegistrationContext} from "../../../contexts";

export type CompetitionType = WeeklyCompetition | RockettRelays | AllSchoolsChampionships | WinterChampionships;

interface SubRegistrationFlowProps {
  onSubmit: any
}

const MultiRegistration = ({onSubmit, competition, details: Details}: SubRegistrationFlowProps & React.PropsWithChildren<{
  competition: CompetitionType
  details: FunctionComponent<{ competition: CompetitionType }>
}>) => {
  const { contactDetails, updateContactDetails } = useWeeklyRegistrationContext();
  const [conditionsOfEntryModalOpen, setConditionsOfEntryModalOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setConditionsOfEntryModalOpen(true);
  };
  const handleClose = () => {
    setConditionsOfEntryModalOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      agree: false,
      firstName: contactDetails?.firstName ?? "",
      lastName: contactDetails?.lastName ?? "",
      emailAddress: contactDetails?.emailAddress ?? ""
    },
    validationSchema: object().shape({
      agree: boolean()
        .required('The conditions of entry must be accepted.')
        .oneOf([true], 'The conditions of entry must be accepted.'),
    }),
    onSubmit: async (values) => {
      formik.setStatus(null);

      updateContactDetails({
          emailAddress: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName,
      })

      onSubmit();
    },
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" color="primary">
            Contact Details
          </Typography>
          {/*<FieldValue label="Email" name="email" value={userAccount?.email}/>*/}
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            error={!!(formik.errors.emailAddress && formik.touched.emailAddress)}
            label="Email"
            name="emailAddress"
            value={formik.values.emailAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.emailAddress && formik.touched.emailAddress && formik.errors.emailAddress}
            disabled={formik.isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={!!(formik.errors.firstName && formik.touched.firstName)}
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.firstName && formik.touched.firstName && formik.errors.firstName}
            disabled={formik.isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={!!(formik.errors.lastName && formik.touched.lastName)}
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.lastName && formik.touched.lastName && formik.errors.lastName}
            disabled={formik.isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Details competition={competition} />

        <Grid item xs={12}>
          <FormControl error={!!(formik.errors.agree && formik.touched.agree)} required>
            <FormControlLabel
              onChange={formik.handleChange}
              checked={formik.values.agree}
              name="agree"
              value={formik.values.agree}
              disabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              control={<Checkbox/>}
              label="I agree to the conditions of entry"
            />
          </FormControl>
          {formik.errors.agree && formik.touched.agree && (
            <FormHelperText error={!!formik.errors.agree}>{formik.errors.agree}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          {map((a: { type: string; message: string }) => (
            <Blockquote key={a.message.substr(0, 6)} color={a.type === 'success' ? 'primary' : 'danger'}>
              <p>{a.message}</p>
            </Blockquote>
          ))(formik.status)}
          <Typography onClick={handleOpen} style={{textDecoration: 'underline', cursor: 'pointer'}} gutterBottom>
            Conditions of Entry
          </Typography>
          <br/>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.agree}>
            Register
          </Button>
          <ConditionsOfEntryDialog open={conditionsOfEntryModalOpen} onClose={handleClose}/>
        </Grid>
      </Grid>
    </form>
  );
};

export {MultiRegistration};
