import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { StepNavigator, StepProps } from '../event-registration-flow';
import { useRegistration } from '@constants/registration-context';
import {LoadingComponent} from "@queensland-running/qr-components";
import {useCompetitionDetailsQuery} from "../queries.generated";
import {WeeklyRegistrationConfirmation} from "./confirmation/weekly-registration-confirmation";
import {useWeeklyRegistrationContext} from "../../../../contexts";

export const SelectConfirmationStep  = (props: StepProps) => {
const { isRegistrationReady } = useWeeklyRegistrationContext();

  useEffect(() => {
    if (isRegistrationReady === false) {
      const timer = setTimeout(() => {
        props.handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [isRegistrationReady, props.handleBack])

  if (isRegistrationReady === null) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming registration..."/>
      </>
    );
  }

  // switch (competition?.__typename) {
  //   case 'WeeklyCompetition':
      return <WeeklyRegistrationConfirmation {...props} />
    // default:
    //   return null;
  // }
};
