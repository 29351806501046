/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RegisterContactDetailsForCompetitionMutationVariables = Types.Exact<{
  input: Types.RegisterContactDetailsForCompetitionInputPartial;
}>;

export type RegisterContactDetailsForCompetitionMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerContactDetailsForCompetition:
        | ({ readonly __typename: 'WeeklyCompetitionRegistration' } & Pick<
            Types.WeeklyCompetitionRegistration,
            'id'
          > & {
              readonly contactDetails?: Types.Maybe<
                { readonly __typename?: 'ContactDetails' } & Pick<
                  Types.ContactDetails,
                  'firstName' | 'lastName' | 'emailAddress' | 'phoneNumber'
                >
              >;
              readonly runnerDetails?: Types.Maybe<
                ReadonlyArray<
                  { readonly __typename?: 'WeeklyCompetitionRunnerDetails' } & Pick<
                    Types.WeeklyCompetitionRunnerDetails,
                    'id' | 'firstName'
                  > & {
                      readonly item?: Types.Maybe<
                        { readonly __typename?: 'RunnerCatalogItem' } & Pick<
                          Types.RunnerCatalogItem,
                          'id' | 'price' | 'name'
                        >
                      >;
                    }
                >
              >;
            })
        | ({ readonly __typename: 'RockettRelaysRegistration' } & Pick<Types.RockettRelaysRegistration, 'id'> & {
              readonly contactDetails?: Types.Maybe<
                { readonly __typename?: 'ContactDetails' } & Pick<
                  Types.ContactDetails,
                  'firstName' | 'lastName' | 'emailAddress' | 'phoneNumber'
                >
              >;
            });
    }
  >;
};

export type RegisterPaymentForWeeklyCompetitionMutationVariables = Types.Exact<{
  input: Types.RegisterPaymentForWeeklyCompetitionInputPartial;
}>;

export type RegisterPaymentForWeeklyCompetitionMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerPaymentForWeeklyCompetition: { readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<
        Types.WeeklyCompetitionRegistration,
        'id'
      >;
    }
  >;
};

export type RegisterPaymentForRockettRelaysMutationVariables = Types.Exact<{
  input: Types.RegisterPaymentForRockettRelaysInputPartial;
}>;

export type RegisterPaymentForRockettRelaysMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerPaymentForRockettRelays: { readonly __typename?: 'RockettRelaysRegistration' } & Pick<
        Types.RockettRelaysRegistration,
        'id'
      >;
    }
  >;
};

export const RegisterContactDetailsForCompetitionDocument = gql`
  mutation registerContactDetailsForCompetition($input: RegisterContactDetailsForCompetitionInputPartial!) {
    competition {
      registerContactDetailsForCompetition(input: $input) {
        __typename
        ... on WeeklyCompetitionRegistration {
          id
          contactDetails {
            firstName
            lastName
            emailAddress
            phoneNumber
          }
          runnerDetails {
            id
            firstName
            item {
              id
              price
              name
            }
          }
        }
        ... on RockettRelaysRegistration {
          id
          contactDetails {
            firstName
            lastName
            emailAddress
            phoneNumber
          }
        }
      }
    }
  }
`;
export type RegisterContactDetailsForCompetitionMutationFn = Apollo.MutationFunction<
  RegisterContactDetailsForCompetitionMutation,
  RegisterContactDetailsForCompetitionMutationVariables
>;
export type RegisterContactDetailsForCompetitionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables
  >,
  'mutation'
>;

export const RegisterContactDetailsForCompetitionComponent = (
  props: RegisterContactDetailsForCompetitionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables
  >
    mutation={RegisterContactDetailsForCompetitionDocument}
    {...props}
  />
);

export type RegisterContactDetailsForCompetitionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables
  >;
} &
  TChildProps;
export function withRegisterContactDetailsForCompetition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables,
    RegisterContactDetailsForCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables,
    RegisterContactDetailsForCompetitionProps<TChildProps, TDataName>
  >(RegisterContactDetailsForCompetitionDocument, {
    alias: 'registerContactDetailsForCompetition',
    ...operationOptions,
  });
}

/**
 * __useRegisterContactDetailsForCompetitionMutation__
 *
 * To run a mutation, you first call `useRegisterContactDetailsForCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterContactDetailsForCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerContactDetailsForCompetitionMutation, { data, loading, error }] = useRegisterContactDetailsForCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterContactDetailsForCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables
  >,
) {
  return Apollo.useMutation<
    RegisterContactDetailsForCompetitionMutation,
    RegisterContactDetailsForCompetitionMutationVariables
  >(RegisterContactDetailsForCompetitionDocument, baseOptions);
}
export type RegisterContactDetailsForCompetitionMutationHookResult = ReturnType<
  typeof useRegisterContactDetailsForCompetitionMutation
>;
export type RegisterContactDetailsForCompetitionMutationResult = Apollo.MutationResult<
  RegisterContactDetailsForCompetitionMutation
>;
export type RegisterContactDetailsForCompetitionMutationOptions = Apollo.BaseMutationOptions<
  RegisterContactDetailsForCompetitionMutation,
  RegisterContactDetailsForCompetitionMutationVariables
>;
export const RegisterPaymentForWeeklyCompetitionDocument = gql`
  mutation registerPaymentForWeeklyCompetition($input: RegisterPaymentForWeeklyCompetitionInputPartial!) {
    competition {
      registerPaymentForWeeklyCompetition(input: $input) {
        id
      }
    }
  }
`;
export type RegisterPaymentForWeeklyCompetitionMutationFn = Apollo.MutationFunction<
  RegisterPaymentForWeeklyCompetitionMutation,
  RegisterPaymentForWeeklyCompetitionMutationVariables
>;
export type RegisterPaymentForWeeklyCompetitionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables
  >,
  'mutation'
>;

export const RegisterPaymentForWeeklyCompetitionComponent = (
  props: RegisterPaymentForWeeklyCompetitionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables
  >
    mutation={RegisterPaymentForWeeklyCompetitionDocument}
    {...props}
  />
);

export type RegisterPaymentForWeeklyCompetitionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables
  >;
} &
  TChildProps;
export function withRegisterPaymentForWeeklyCompetition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables,
    RegisterPaymentForWeeklyCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables,
    RegisterPaymentForWeeklyCompetitionProps<TChildProps, TDataName>
  >(RegisterPaymentForWeeklyCompetitionDocument, {
    alias: 'registerPaymentForWeeklyCompetition',
    ...operationOptions,
  });
}

/**
 * __useRegisterPaymentForWeeklyCompetitionMutation__
 *
 * To run a mutation, you first call `useRegisterPaymentForWeeklyCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPaymentForWeeklyCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPaymentForWeeklyCompetitionMutation, { data, loading, error }] = useRegisterPaymentForWeeklyCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPaymentForWeeklyCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables
  >,
) {
  return Apollo.useMutation<
    RegisterPaymentForWeeklyCompetitionMutation,
    RegisterPaymentForWeeklyCompetitionMutationVariables
  >(RegisterPaymentForWeeklyCompetitionDocument, baseOptions);
}
export type RegisterPaymentForWeeklyCompetitionMutationHookResult = ReturnType<
  typeof useRegisterPaymentForWeeklyCompetitionMutation
>;
export type RegisterPaymentForWeeklyCompetitionMutationResult = Apollo.MutationResult<
  RegisterPaymentForWeeklyCompetitionMutation
>;
export type RegisterPaymentForWeeklyCompetitionMutationOptions = Apollo.BaseMutationOptions<
  RegisterPaymentForWeeklyCompetitionMutation,
  RegisterPaymentForWeeklyCompetitionMutationVariables
>;
export const RegisterPaymentForRockettRelaysDocument = gql`
  mutation registerPaymentForRockettRelays($input: RegisterPaymentForRockettRelaysInputPartial!) {
    competition {
      registerPaymentForRockettRelays(input: $input) {
        id
      }
    }
  }
`;
export type RegisterPaymentForRockettRelaysMutationFn = Apollo.MutationFunction<
  RegisterPaymentForRockettRelaysMutation,
  RegisterPaymentForRockettRelaysMutationVariables
>;
export type RegisterPaymentForRockettRelaysComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables
  >,
  'mutation'
>;

export const RegisterPaymentForRockettRelaysComponent = (props: RegisterPaymentForRockettRelaysComponentProps) => (
  <ApolloReactComponents.Mutation<
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables
  >
    mutation={RegisterPaymentForRockettRelaysDocument}
    {...props}
  />
);

export type RegisterPaymentForRockettRelaysProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables
  >;
} &
  TChildProps;
export function withRegisterPaymentForRockettRelays<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables,
    RegisterPaymentForRockettRelaysProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables,
    RegisterPaymentForRockettRelaysProps<TChildProps, TDataName>
  >(RegisterPaymentForRockettRelaysDocument, {
    alias: 'registerPaymentForRockettRelays',
    ...operationOptions,
  });
}

/**
 * __useRegisterPaymentForRockettRelaysMutation__
 *
 * To run a mutation, you first call `useRegisterPaymentForRockettRelaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPaymentForRockettRelaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPaymentForRockettRelaysMutation, { data, loading, error }] = useRegisterPaymentForRockettRelaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPaymentForRockettRelaysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPaymentForRockettRelaysMutation,
    RegisterPaymentForRockettRelaysMutationVariables
  >,
) {
  return Apollo.useMutation<RegisterPaymentForRockettRelaysMutation, RegisterPaymentForRockettRelaysMutationVariables>(
    RegisterPaymentForRockettRelaysDocument,
    baseOptions,
  );
}
export type RegisterPaymentForRockettRelaysMutationHookResult = ReturnType<
  typeof useRegisterPaymentForRockettRelaysMutation
>;
export type RegisterPaymentForRockettRelaysMutationResult = Apollo.MutationResult<
  RegisterPaymentForRockettRelaysMutation
>;
export type RegisterPaymentForRockettRelaysMutationOptions = Apollo.BaseMutationOptions<
  RegisterPaymentForRockettRelaysMutation,
  RegisterPaymentForRockettRelaysMutationVariables
>;
