import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
// import {RegisterRunner, Runner} from '@generated/schema';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import {useRegisterContactDetailsForCompetitionMutation} from "../../mutations.generated";
import {useWeeklyRegistrationContext} from "../../../../../contexts";

export const WeeklyRegistrationConfirmation = ({handleNext, handleBack, competitionId}: StepProps) => {
  const {contactDetails, runnerDetails} = useWeeklyRegistrationContext();
  const [registerRunnersForEvent] = useRegisterContactDetailsForCompetitionMutation({
    onCompleted() {
      handleNext!();
    },
  });

  useEffect(() => {
    console.log({
      contactDetails,
      runnerDetails
    })
  }, [contactDetails, runnerDetails]);

  const handleSubmit = () => {
    registerRunnersForEvent({
      variables: {
        input: {
          competitionId,
          username: contactDetails?.emailAddress,
          contactDetails: {
            firstName: contactDetails?.firstName ?? "",
            lastName: contactDetails?.lastName ?? "",
          },
          runnerDetails: runnerDetails
            .map((runner) => ({
              id: runner.id,
              bibNumber: runner.bibNumber,
              club: runner.club,
              firstName: runner.firstName,
              lastName: runner.lastName,
              gender: null,
              dateOfBirth: runner.dateOfBirth
            }))
            // .map(
            //   ({id, bibNumber, club, firstName, lastName, ...runner}: Runner): RegisterRunner => ({
            //     id,
            //     bibNumber,
            //     club,
            //     firstName,
            //     lastName,
            //     gender: null,
            //     dateOfBirth: runner.dateOfBirth, //moment(runner.dateOfBirth!).format('YYYY-MM-DD')
            //   }),
            // ),
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        Confirm
      </Typography>
      {JSON.stringify({contactDetails, runnerDetails})}
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        handleNext={handleSubmit}
        nextText={'Next'}
      />
    </>
  );
};
