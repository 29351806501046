import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { WeeklyRunnersInput,} from '@queensland-running/qr-components';
import {RegisterWeeklyCompetitionRunner, Runner} from '@generated/schema';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import {useCompetitionDetailsQuery} from '../../queries.generated';
import {useWeeklyRegistrationContext} from "../../../../../contexts";

interface RegisterForEventProps {
  competitionId: string
}

type RunnerWithId = RegisterWeeklyCompetitionRunner & { id: string, index: number; isEditMode: boolean };
const useRegisterForEvent = ({competitionId}: RegisterForEventProps) => {
  const {data: eventData, loading} = useCompetitionDetailsQuery({
    variables: {
      id: competitionId,
    }
  });

  return {
    runners: (eventData?.runners?.data! as Runner[]) ?? [],
    competition: eventData?.competition,
    userAccount: eventData?.userAccount,
    loading,
  };
};
export const WeeklyRegistration = ({handleNext, handleSkip, handleBack, competitionId}: StepProps) => {
  const {runnerDetails, updateRunners} = useWeeklyRegistrationContext();
  const {competition} = useRegisterForEvent({competitionId});
  const [temporaryRunners, setTemporaryRunners] = useState<RunnerWithId[]>(runnerDetails);

  const isEditing = !!temporaryRunners.find(({isEditMode}) => isEditMode) ?? false;

  const onSubmit = () => {
    updateRunners(temporaryRunners);

    handleNext && handleNext()
  }

  return (
    <>
      <Typography variant="h5" color="primary">
        Who is running?
      </Typography>
      <WeeklyRunnersInput
        label="Runners"
        name="my-runners"
        errors={''}
        value={temporaryRunners}
        onChange={(runners) => {
          setTemporaryRunners(runners);
        }}
        onSave={(runners) => {
          console.log('Saving runners', runners)
          updateRunners(runners)
        }}
        eventDate={competition?.day}
      />
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        handleNext={onSubmit}
        nextText={'Next'}
        nextDisabled={isEditing}
      />
    </>
  );
};
