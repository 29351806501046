import React, {createContext, ReactNode, useContext, useMemo} from 'react';

import {ContactDetails, RegisterWeeklyCompetitionRunner} from "@generated/schema";
import {useWeeklyRegistration} from "@queensland-running/qr-components";

type RunnerWithId = RegisterWeeklyCompetitionRunner & { id: string, index: number; isEditMode: boolean };

const WeeklyRegistrationContext = createContext<WeeklyRegistrationContext>({
  updateRunners: () => {
  },
  updateContactDetails: () => {
  },
  runnerDetails: [],
  contactDetails: undefined,
  isRunnerDetailsReady: null,
  isRegistrationReady: null,
  clear: () => {
  }
});

type WeeklyRegistrationContext = {
  updateRunners: (runnerDetails: any) => void;
  updateContactDetails: (contactDetails: any) => void;
  runnerDetails: RunnerWithId[],
  contactDetails?: ContactDetails | undefined,
  isRunnerDetailsReady: boolean | null,
  isRegistrationReady: boolean | null,
  clear: () => void
}

const useWeeklyRegistrationContext = () => {
  const config = useContext(WeeklyRegistrationContext);

  if (!config) {
    throw new Error('Configuration should already be loaded');
  }

  return config;
};

const WeeklyRegistrationProvider = ({children}: { children: ReactNode }) => {
  const {
    updateRunners,
    runnerDetails,
    updateContactDetails,
    contactDetails,
    isRunnerDetailsReady,
    isRegistrationReady,
    clear
  } = useWeeklyRegistration();

  console.log({
    runnerDetails,
    contactDetails
  })

  return <WeeklyRegistrationContext.Provider value={{
    updateRunners,
    runnerDetails,
    updateContactDetails,
    contactDetails,
    isRunnerDetailsReady,
    isRegistrationReady,
    clear
  }}>{children}</WeeklyRegistrationContext.Provider>;
};

export {WeeklyRegistrationProvider, useWeeklyRegistrationContext};
