import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import {
  Blockquote,
  Button,
} from '@queensland-running/qr-components';
import { SelectRunnersStep } from './steps/select-runners-step';
import { ContactDetailsStep } from './steps/contact-details-step';
import { SelectConfirmationStep } from './steps/select-confirmation-step';
import {PreviousRegistrationStep} from "./steps/previous-registration-step";
import {useConfig} from "@constants/config";
import {CompetitionType} from "../../../types";
import {useWeeklyRegistrationContext} from "../../../contexts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Contact Details', 'Select runners', 'Confirm'];
}

export interface StepProps {
  competitionId: string;
  competitionType: CompetitionType['__typename'];
  handleNext?: () => void;
  handleBack?: () => void;
  handleSkip?: () => void;
}

function getStepContent(step: number, props: StepProps) {
  switch (step) {
    case -1:
      return <PreviousRegistrationStep {...props} />
    case 0:
      return <ContactDetailsStep {...props} />;
    case 1:
      return <SelectRunnersStep {...props} />;
    case 2:
      return <SelectConfirmationStep {...props} />;
    default:
      return 'Unknown step';
  }
}

const isStepOptional = (step: number) => {
  return step === 1;
};

const isStepSkipped = (step: number, skipped: Set<number>) => {
  return skipped.has(step);
};

interface EventRegistrationFlowProps {
  competitionId: string;
  competitionType: CompetitionType['__typename'];
}

export default function EventRegistrationFlow({ competitionId, competitionType }: EventRegistrationFlowProps) {
  const classes = useStyles();
  const { clear } = useWeeklyRegistrationContext();
  const { toggles } = useConfig()
  const [activeStep, setActiveStep] = React.useState(0); // TODO - handle previous registration
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();

  if (!toggles.registration) {
    return (
      <Blockquote color="primary">
        <p>Event Registration is currently unavailable for this event.</p>
      </Blockquote>
    );
  }

  //  if (competitionHasPassed) {
  //     return (
  //       <Blockquote color="secondary">
  //         <p>
  //           Online Registration for this event has now closed. For upcoming events, visit the{' '}
  //           <a href={Routes[RouteKeys.calendar].replace(':year', '2021')}>Calendar</a>.
  //         </p>
  //       </Blockquote>
  //     );
  //   }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep, skipped)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    clear();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          // const labelProps: { optional?: React.ReactNode } = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
          // }
          if (isStepSkipped(index, skipped)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button} title={'Submit another?'} />
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions} />
            {getStepContent(activeStep, { competitionId, competitionType, handleNext, handleBack, handleSkip })}
          </div>
        )}
      </div>
    </div>
  );
}

export const StepNavigator = ({
  handleBack,
  handleNext,
  handleSkip,
  backText,
  nextText,
  skipText,
  nextDisabled = false,
}: {
  handleBack?: () => void;
  handleNext?: () => void;
  handleSkip?: () => void;
  backText?: string;
  nextText?: string;
  skipText?: string;
  nextDisabled?: boolean
}) => (
  <div>
    {backText && <Button onClick={handleBack} title={backText} />}
    {skipText && <Button variant="contained" onClick={handleSkip} title={skipText} />}
    {nextText && <Button variant="contained" color="primary" onClick={handleNext} title={nextText} disabled={nextDisabled} />}
  </div>
);
